@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
// @include foundation-button;
// @include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

// @include motion-ui-transitions;
// @include motion-ui-animations;

$debug: false;



%lead-font-size {
	font-weight: $global-weight-bold;
	@include breakpoint(medium) {
		font-size: $global-font-size * 1.25;
	}
	@include breakpoint(large) {
		font-size: $global-font-size * 1.5;
	}
}


body {
	background: $black;
	color: $white;
}

p {
	margin-bottom: .75rem;
}

.slide {
	min-height: 100vh;
}

h1, h2 {
	line-height: 1;
	text-transform: uppercase;
}

h2 {
	margin-bottom: 3em;
}

img {
	width: 75%;
	margin-top: 2em;
	@include breakpoint(medium) {
		width: 50%;
	}
	@include breakpoint(large) {
		width: 75%;
		margin-top: 0;
	}
}

.u-p-like {
	color: inherit;
}


.lead {
	@extend %lead-font-size;
}


blockquote {
	margin: 0;
	padding: 0;
	border: none;
	p {
		@extend %lead-font-size;
		color: $white;
		font-weight: $global-weight-normal;
		line-height: 1.4;
		margin-bottom: 1.5em;
	}
	cite {
		text-align: right;
		color: $white;
		font-style: normal;
		font-size: .875rem;
		@include breakpoint(medium) {
			font-size: $global-font-size;
		}
		@include breakpoint(large) {
			font-size: $global-font-size * 1.125;
		}
		&:before {
			content: "";
		}
	}
}

.next-link {
	img {
		transition: all 1.2s ease;
		width: 60px;
		position: relative;
	}
	&:hover img {
		top: 4px;
		cursor: pointer;
	}
}


#slide4 {
	// background: green;
}


@if($debug == true) {

	.grid-container {
		background: green;
	}

	.grid-x {
		background: red;
	}
	.small-12.cell {
		background: pink;
	}

}